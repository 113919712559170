angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('signFast', {
            url: '/signDocument/fast/:token',
            views: {
                "content": {
                    controller: 'SignFastCtrl',
                    templateUrl: 'web/sign/fast/fast.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Sign Document'
            }
        });
    })
    .controller('SignFastCtrl', function ($rootScope, $scope, session, signatureService, $stateParams, $sce, $timeout, $translate, swalService, $window) {

        var that = this;

        ////////////////////////
        // Inicio INICIALIZAR
        ///////////////////////
        that.token = $stateParams.token;
        that.pageURLS = [];


        signatureService.retrieveFirmaByToken($stateParams.token).then(function (response) {
            that.loaded = true;
            that.sign = response.data.signature;
            that.firmaMultiple = response.data.signatureSinger;
            for (var k = 0; k < that.sign.signers.length; k++) {
                if (that.sign.signers[k].id == that.firmaMultiple.id) {
                    that.actualFirmante = that.sign.signers[k];
                }
            }

            function loadPage(i) {
                signatureService.getTokenURL($stateParams.token, that.sign.id, i).then(function (response) {
                    that.pageURLS[i] = response.data;
                });
            }
            for (var i = 0; i < that.sign.pages; i++) {
                loadPage(i);
            }
        });



        ////////////////////////
        // fin INICIALIZAR
        ////////////////////////


        that.rechazar = () =>
            swalService.requestSwal("Rechazar", 'Una vez rechazado, no se podrá volver a firmar ni editar.', 'info', () => signatureService.rechazarByToken($stateParams.token, that.actualFirmante))
                .then(response => {
                    swal($translate.instant("Documento rechazado"), $translate.instant("Se ha rechazado el documento correctamente"), "success");
                    window.location = "https://" + $rootScope.dominio;
                }, error => swal($translate.instant('Algo ha ido mal'), $translate.instant("Ha habido un error rechazando el documento"), "error")
                );


        that.firmar = () =>
            swalService.requestSwal("Firmar documento", 'Una vez firmado, no se podrá volver a firmar ni editar.', 'info', () => signatureService.firmarByToken($stateParams.token, that.actualFirmante))
                .then(function (response) {
                    swal($translate.instant("Documento Firmado"), $translate.instant("Has firmado el documento correctamente"), "success");
                    if (FingerinkEnvironmentAngular.trackGoogleFacebookTags) {
                        $window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'Signatures',
                            eventAction: 'sign',
                            eventLabel: 'Fast'
                        });
                    }
                    // window.location = "https://" + $rootScope.dominio;
                    // he tirado por la forma facil ,ahora tu pasale variables y dejalo funcional de verdad xd
                    $state.go('endSign');
                }, error => swal($translate.instant("Error firmando el documento"), $translate.instant("No se ha podido firmar el documento"), "error")
                );

    });
